import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import React from "react";
import { ReferralUserId } from "../../../shared/schema/schema";
import { createDisclosureTriggerComponent } from "../../../shared/utils/create-disclosure-trigger-component";
import CreateReferralUserForm from "./CreateReferralUserForm";

type Props = {
  children: React.ReactElement;
  onCreated: (id: ReferralUserId, email: string, password: string) => void;
};

const CreateReferralUserModalTrigger = createDisclosureTriggerComponent<Props>({
  displayName: "CreateReferralUserModalTrigger",
  render: ({ disclosure, onCreated }) => {
    return (
      <Modal {...disclosure} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <CreateReferralUserForm
              onSuccessfulSubmit={(id, email, password) => {
                onCreated(id, email, password);
                disclosure.onClose();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  },
});

export default CreateReferralUserModalTrigger;
