import { Flex, Tag } from "@chakra-ui/react";
import { AuthPermissionGuard } from "../../../../shared/components/AuthPermissionGuard";
import Page, { PageTab } from "../../../../shared/components/Page";
import ReferralsDashboardRoute from "../../../referral/pages/dashboard/ReferralsDashboardRoute";
import ParallelCallsV2Route from "../parallel-calls/ParallelCallsV2Route";
import IntakeDashboardRoute from "./IntakeDashboardRoute";
import IntakePlanReferralsRoute from "./IntakePlanReferralsRoute";

const tabs: PageTab[] = [
  {
    header: "Intake Patients",
    route: "app.patients.intake.dashboard",
    component: <IntakeDashboardRoute />,
  },
  {
    header: "Plan Referrals",
    route: "app.patients.intake.plans",
    component: (
      <AuthPermissionGuard permission="view_patient_page_intake">
        <IntakePlanReferralsRoute />
      </AuthPermissionGuard>
    ),
  },
  {
    header: (
      <Flex gap={2}>
        Parallel Dialer{" "}
        <Tag colorScheme="blue" size="sm">
          Experimental
        </Tag>
      </Flex>
    ),
    route: "app.patients.intake.parallel-calls",
    component: (
      <AuthPermissionGuard permission="view_patient_page_intake">
        <ParallelCallsV2Route />
      </AuthPermissionGuard>
    ),
  },
  {
    header: "Referral Users",
    route: "app.patients.intake.referral-users",
    component: (
      <AuthPermissionGuard permission="view_patient_page_intake">
        <ReferralsDashboardRoute />
      </AuthPermissionGuard>
    ),
  },
];

export default function IntakeRoute() {
  return <Page.Tabbed tabs={tabs} />;
}
