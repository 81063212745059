import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import useApi from "../../../shared/hooks/useApi";
import { ReferralUserId } from "../../../shared/schema/schema";

type Props = {
  isOpen: boolean;
  id: ReferralUserId;
  email: string;
  password?: string;
  onResetPassword: (newPassword: string) => void;
  onClose: () => void;
};

const ReferralUserModal: React.FC<Props> = ({
  id,
  email,
  password,
  isOpen,
  onResetPassword,
  onClose,
}) => {
  const { api } = useApi();
  const toast = useToast();
  const { mutate: resetPassword, isPending } = useMutation({
    mutationFn: () =>
      api.post("./referral_users/:referralUserId/reset_password", { path: { referralUserId: id } }),
    onSuccess: (response) => onResetPassword(response.generatedPassword),
    onError: (error) => {
      toast({
        title: "Error resetting password",
        description: error.message,
        status: "error",
        position: "top-right",
      });
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Referral User Login Info</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Text fontWeight="bold">Email:</Text>
            <Text mb={3}>{email}</Text>

            <Text fontWeight="bold">Password:</Text>
            {password ? (
              <Text>{password}</Text>
            ) : (
              <Box>
                *********
                <Button
                  colorScheme="red"
                  isLoading={isPending}
                  ml="1"
                  variant="link"
                  onClick={() => resetPassword()}
                >
                  (Reset Password)
                </Button>
              </Box>
            )}
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReferralUserModal;
