import { AxiosError } from "axios";
import { z, ZodError } from "zod";
import { PhoneProviderError } from "../../modules/call-center/call-center.errors";

const customErrorSchema = z.object({
  data: z.object({
    error: z.string(),
    details: z.string().array().optional(),
  }),
});

const customErrorStringDetailsSchema = z.object({
  data: z.object({
    error: z.string(),
    details: z.string().optional(),
  }),
});

export function formatErrorResponse(error: unknown) {
  if (error instanceof ZodError) {
    return error.errors
      .map((err) => {
        const path = err.path.join(".");
        const message = err.message;
        return `${path}: ${message}`;
      })
      .join(", ");
  }

  if (error instanceof AxiosError) {
    const err = customErrorSchema.safeParse(error.response);
    if (err.success) {
      return err.data.data.details?.join(", ") ?? err.data.data.error;
    }

    const errStringDetails = customErrorStringDetailsSchema.safeParse(error.response);
    if (errStringDetails.success) {
      return errStringDetails.data.data.details ?? errStringDetails.data.data.error;
    }
  }

  if (error instanceof PhoneProviderError) {
    return error.message;
  }

  return `${error}`;
}
