import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Switch,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { BodyOf } from "../../../core/api";
import PhoneNumberInput from "../../../shared/components/PhoneNumberInput";
import useApi from "../../../shared/hooks/useApi";
import useControlledForm from "../../../shared/hooks/useControlledForm";
import { AgencyPhonebookContactId, ReferralUserId } from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";
import { EntitySelect } from "../../workflow/components/EntityFormControl";

const zCreateReferralUserForm = z.object({
  usePhonebook: z.boolean().optional(),
  fullName: z.string().optional(),
  phone: z
    .string()
    .transform((x) => phoneFormatter.formatE164(x))
    .optional(),
  email: z.string().min(1).email(),
  role: z.string().optional(),
  organization: z.string().optional(),
  phonebookContactId: AgencyPhonebookContactId.optional(),
});

export default function ReferralUserForm(props: {
  onSuccessfulSubmit: (id: ReferralUserId, email: string, password: string) => void;
}) {
  const toast = useToast();
  const { api } = useApi();
  const { isPending: isCreatingReferralUser, mutate: createReferralUser } = useMutation({
    mutationFn: (body: BodyOf<"post", "./referral_users">) =>
      api.post("./referral_users", { body }),
    onError: (error) => {
      toast({
        title: "Error creating referral user",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });
  const {
    isPending: isCreatingReferralUserByPhonebookContact,
    mutate: createReferralUserByPhonebookContact,
  } = useMutation({
    mutationFn: ({
      phonebookContactId,
      body,
    }: {
      phonebookContactId: AgencyPhonebookContactId;
      body: BodyOf<"post", "./referral_users/phonebook_contacts/:phonebookContactId">;
    }) => {
      return api.post("./referral_users/phonebook_contacts/:phonebookContactId", {
        body,
        path: { phonebookContactId },
      });
    },
    onError: (error) => {
      toast({
        title: "Error creating referral user",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });
  const form = useControlledForm({
    schema: zCreateReferralUserForm,
    onSuccess: (data) => {
      if (data.phonebookContactId) {
        createReferralUserByPhonebookContact(
          {
            phonebookContactId: data.phonebookContactId,
            body: {
              email: data.email,
              role: data.role ?? null,
              organization: data.organization ?? null,
            },
          },
          {
            onSuccess: (response) =>
              props.onSuccessfulSubmit(response.id, data.email, response.generatedPassword),
          }
        );
      } else {
        createReferralUser(
          {
            name: data.fullName ?? "",
            email: data.email,
            phoneNumber: data.phone ?? "",
            role: data.role ?? null,
            organization: data.organization ?? null,
          },
          {
            onSuccess: (response) =>
              props.onSuccessfulSubmit(response.id, data.email, response.generatedPassword),
          }
        );
      }
    },
    onError: (error) => {
      toast({
        title: "Error creating referral user",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  return (
    <Box maxWidth="500px" mx="auto" p={4}>
      <form onSubmit={form.onSubmit}>
        <VStack spacing={4}>
          <FormControl
            alignItems="center"
            display="flex"
            gap={4}
            isInvalid={form.isInvalid("usePhonebook")}
          >
            <Switch
              id="use-phonebook"
              isChecked={form.state.usePhonebook ?? false}
              mb={0}
              onChange={(e) => form.setValue("usePhonebook", e.target.checked)}
            />
            <FormLabel htmlFor="use-phonebook" mb="0">
              From existing phonebook contact
            </FormLabel>
            <FormErrorMessage>{form.getError("usePhonebook")}</FormErrorMessage>
          </FormControl>

          {form.state.usePhonebook ? (
            <>
              <FormControl isRequired isInvalid={form.isInvalid("phonebookContactId")}>
                <FormLabel htmlFor="contactId">Phonebook Contact</FormLabel>
                <EntitySelect
                  input={{ type: "entity", entity: "Phonebook Contact" }}
                  label="Phonebook Contact"
                  multiple={false}
                  renderUnselected="Select a phonebook contact"
                  value={form.state.phonebookContactId ?? null}
                  onChange={(x) => form.setValue("phonebookContactId", x)}
                />
              </FormControl>
            </>
          ) : (
            <>
              <FormControl isRequired isInvalid={form.isInvalid("fullName")}>
                <FormLabel htmlFor="fullName">Full Name</FormLabel>
                <Input
                  id="fullName"
                  name="fullName"
                  value={form.state.fullName ?? ""}
                  onChange={(e) => form.setValue("fullName", e.target.value)}
                />
                <FormErrorMessage>{form.getError("fullName")}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={form.isInvalid("phone")}>
                <FormLabel htmlFor="phone">Phone Number</FormLabel>
                <PhoneNumberInput
                  id="phone"
                  name="phone"
                  placeholder="(123) 456-7890"
                  value={form.state.phone ?? ""}
                  onChange={(x) => form.setValue("phone", x)}
                />
                <FormErrorMessage>{form.getError("phone")}</FormErrorMessage>
              </FormControl>
            </>
          )}

          <FormControl isRequired isInvalid={form.isInvalid("email")}>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              id="email"
              name="email"
              type="email"
              value={form.state.email ?? ""}
              onChange={(e) => form.setValue("email", e.target.value)}
            />
            <FormErrorMessage>{form.getError("email")}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={form.isInvalid("role")}>
            <FormLabel htmlFor="role">Role</FormLabel>
            <Input
              id="role"
              name="role"
              value={form.state.role ?? ""}
              onChange={(e) => form.setValue("role", e.target.value)}
            />
            <FormErrorMessage>{form.getError("role")}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={form.isInvalid("organization")}>
            <FormLabel htmlFor="organization">Organization</FormLabel>
            <Input
              id="organization"
              name="organization"
              value={form.state.organization ?? ""}
              onChange={(e) => form.setValue("organization", e.target.value)}
            />
            <FormErrorMessage>{form.getError("organization")}</FormErrorMessage>
          </FormControl>

          <Button
            colorScheme="blue"
            isLoading={isCreatingReferralUser || isCreatingReferralUserByPhonebookContact}
            type="submit"
          >
            Submit
          </Button>
        </VStack>
      </form>
    </Box>
  );
}
