import { graphql } from "../../shared/schema/gql";

export const GET_REFERRAL_USERS_QUERY = graphql(`
  query GetReferralUsers(
    $order: [ReferralUser__Order!]
    $limit: Int!
    $id: ReferralUserId_Filter
    $offset: Int!
    $role: String_Filter
    $organization: String_Filter
    $nameSearch: String
    $phoneSearch: String
    $emailSearch: String
    $search: String
  ) {
    referralUsers(
      order: $order
      limit: $limit
      id: $id
      offset: $offset
      role: $role
      organization: $organization
      nameSearch: $nameSearch
      phoneSearch: $phoneSearch
      emailSearch: $emailSearch
      search: $search
    ) {
      totalCount
      nodes {
        id
        phonebookContactId
        name
        email
        role
        organization
        createdAt
        updatedAt
        phoneNumber
      }
    }
  }
`);
