//! @ngInject
export function billingInvoicesCtrl($scope, $rootScope, NgTableParams, $state, itemSearchPageManager, selectionLogic, toaster, billingInvoicesProcessor, DatabaseApi, $filter, FilterUtils, SweetAlert) {    $scope.caregivers = DatabaseApi.caregivers();
    const universalBOM = "\uFEFF";
    $scope.patients = DatabaseApi.patients();
    $scope.offices = DatabaseApi.offices();
    $rootScope.$on("got_caregivers_data", function (event) {
        $scope.caregivers = DatabaseApi.caregivers();
    });

    $rootScope.$on("got_patients_data", function (event) {
        $scope.patients = DatabaseApi.patients();
    });
    $scope.tableView = {
        invoice: {
            tableParams: null,
            serviceCount: 0,
            invoiceCount: 0,
            allItems: []
        },
        service: {
            tableParams: null,
            serviceCount: 0,
            invoiceCount: 0,
            allItems: []
        }
    };
    $scope.openPatientModal = $rootScope.openPatientModal;
    $scope.globalFilter = { val: '' };

    $scope.eBillingProviderOptions = DatabaseApi.eBillingProviders().map(item => ({
        id: item, label: item
    }));

    $scope.viewTypeIndex = 0;
    $scope.viewType = "Invoice";

    $scope.missingTableData = function () {
        const tableView = $scope.viewType === "Invoice" ? $scope.tableView.invoice : $scope.tableView.service;

        return !tableView.tableParams || !tableView.tableParams.data || !tableView.tableParams.data.length
    }

    $scope.changeViewType = function (viewType) {
        $scope.viewType = viewType;
        switch (viewType) {
            case "Invoice":
                $scope.pageManager.updateRelativeURL("/invoices");
                $scope.arTypeIndex = 0;
                initSelection($scope.tableView.invoice.allItems, viewType);
                break;
            case "Service":
                $scope.pageManager.updateRelativeURL("/invoices_by_service");
                $scope.arTypeIndex = 1;
                initSelection($scope.tableView.service.allItems, viewType);
                break;
        }
    }

    $scope.exportStatusOptions = [{
        id: "FULL", label: "Exported"
    },{
        id: "NONE", label: "Not exported"
    },{
        id: "PARTIAL", label: "Partially exported"
    },{
        id: "ERROR", label: "Error"
    }];

    $scope.billTypeOptions = [{
        id: "false", label: "Billable"
    },{
        id: "true", label: "Pending"
    }];

    $scope.serviceTypeOptions = [{
        id: "DIRECT_CARE", label: "Direct Care"
    },{
        id: "ADMINISTRATIVE_PAY", label: "Administrative Payment"
    }];

    $scope.paymentStatus = [{
        id: "FULL", label: "Paid"
    },{
        id: "PARTIAL", label: "Partially Paid"
    },{
        id: "NONE", label: "Not Paid"
    },{
        id: "OVER", label: "Over Paid"
    }];

    $scope.manualHoldOptions = [{
        id: "true", label: "Held"
    },{
        id: "false", label: "Not Held"
    }];

    $scope.hasCheckLineOptions = [{
        id: "true", label: "Has Check Line"
    },{
        id: "false", label: "Missing Check Line"
    }];

    $scope.selectedEBillingProviders = [];
    $scope.selectedExportStatuses = [];
    $scope.selectedPaymentStatuses = [];
    $scope.selectedManualHold = [];
    $scope.selectedHasCheckLine = [];
    $scope.selectedBillType = [{id: "false", label: "Billable"}];
    $scope.selectedServices = [];

    function initialize() {
        initPageManager();
        initSelection([], $scope.viewType);
        $scope.pageManager.resetSearchParamsByUrl();

        // Load filters
        const storageFilters = FilterUtils.rootFilters.billingInvoicesCtrl;
        if (storageFilters !== undefined) {
            $scope.pageManager.setSearchParams(storageFilters);
        }
        $scope.selectedEntity = {
            type: '',
            id: ''
        };
    }

    $scope.$on("$destroy", function () {
        const filters = $scope.pageManager.searchParams;
        FilterUtils.rootFilters.billingInvoicesCtrl = angular.copy(filters);
    });

    const initPageManager = function () {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/invoices");
        $scope.changeViewType("Invoice");

        $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();
        $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();
        $scope.serviceCodesDataManager = $scope.pageManager.getServiceCodesDataManager();

        $scope.pageManager.initFromToDateParams();
        $scope.pageManager.initSearchParam("invoiceId", "", {
            isEntity: true,
        });
        $scope.pageManager.initSearchParam("exportBatchId", "", {
            isEntity: true,
        });
        $scope.pageManager.initSearchParam("selectedPatientId", null);
        $scope.pageManager.initContractTypesParam();
        $scope.pageManager.initServiceCodesParam();
        $scope.pageManager.initOfficeParam();
        $scope.pageManager.initSearchParamDeprecated("exportStatus", "", "exportStatuses", undefined, undefined, "");
        $scope.pageManager.initSearchParamDeprecated("paymentStatus", "", "paymentStatuses", undefined, undefined, "");
        $scope.pageManager.initSearchParamDeprecated("upToDate", "true", "up-to-date", undefined, undefined, true);
        $scope.pageManager.initSearchParamDeprecated("billType", "false", "pending", undefined, undefined, "");
        $scope.pageManager.initSearchParamDeprecated("manualHold", "", "hold", undefined, undefined, "");
        $scope.pageManager.initSearchParamDeprecated("eBillingProvider", "", "e-billing-provider", undefined, undefined, "");
        $scope.pageManager.initSearchParamDeprecated("hasCheckLine", "", "hasCheckLine", undefined, undefined, "");
        $scope.pageManager.initSearchParamDeprecated("serviceType", "", "serviceType", undefined, undefined, "");
        $scope.pageManager.updateSearchParamValue("upToDate", true);
        $scope.pageManager.initSearchParam(
            "invoiceBatchId",
            $state.params.batchId ? $state.params.batchId : "",
            { isEntity: true }
        );
        $scope.pageManager.initSearchParam("dateByInvoice", true, {
            placeholderValue: true,
            queryName: "date-by-invoice",
        });
    };

    const initSelection = function (items, type) {
        $scope.selectionLogic = selectionLogic.createNewLogic(function (item) {
            if (!item.exported) $scope.selectionLogic.addItemToCollection(item);
        }, "id");
        if (items) {
            items.forEach((item) => {
                $scope.selectionLogic.initItem(item);
                item.serviceType = item.invoiceVisitId !== undefined ? "Direct Care" : "Administative Payment";
            });
        }

        const tableParamsHolder = type === "Invoice" ? $scope.tableView.invoice : $scope.tableView.service;

        tableParamsHolder.serviceCount = type === "Service" ? items.length : items.reduce(function (a, b) {
            return a + b.serviceCount;
        }, 0);
        tableParamsHolder.invoiceCount = type === "Service" ? (new Set(items.map(row => row.invoiceId))).size : items.length;
    };

    const mapItems = function (items) {
        items.forEach(function (item) {
            if (!item.contractTypeName && item.contractTypeId) {
                const contractType = $scope.contractTypesDataManager.getContractTypeById(item.contractTypeId);
                item.contractTypeName = (contractType && contractType.name) || item.contractTypeId;
            }

            //should receive the 'exported' indication from the server
            // Doesn't work and hasn't worked for a while (exportBatchId).
            // item.exported = type === 'Invoice' ? Boolean(item.exportBatchId) : item.exportedStatus === "FULL";

            // $scope.selectionLogic.initItem(item);
        });
    };

    const initTable = function (items, type) {
        const officeNameMap = new Map(DatabaseApi.offices().map(office => [office.id, office.name]));
        initSelection(items, type);
        const serviceCodesMap = new Map(DatabaseApi.serviceCodes().map(item => [item.id, item.code]));
        items.forEach(function (item) {
            if (item.caregiverId) {
                item.caregiver = $scope.caregivers[item.caregiverId];
            }
            if (item.patientId) {
                item.patient = $scope.patients[item.patientId];
            }
            if (item.officeId) {
                item.officeName = officeNameMap.get(item.officeId);
            }
            if (item.serviceCodeIds) {
                item.serviceCodes = item.serviceCodeIds.map(id => serviceCodesMap.get(id)).filter(code => code).join();
            }
            if (item.serviceCodeId) {
                item.serviceCode = serviceCodesMap.get(item.serviceCodeId);
            }
        });
        const hasItems = items && items.length;
        if (hasItems) {
            mapItems(items, type);
            // toaster.pop("success", items.length + " Invoices were loaded successfully");
        }

        const options = {
            count: 25
        };

        const tableParamsHolder = type === "Invoice" ? $scope.tableView.invoice : $scope.tableView.service;

        tableParamsHolder.tableParams = new NgTableParams(options, {
            dataset: items
        });
    };

    function setAllItemsByType(response, type) {
        if (type === "Invoice") {
            const items = response.data.invoices;
            $scope.tableView.invoice.allItems = [...items];
            return response.data.invoices;
        }

        if (type === "Service") {
            const items = response.data.invoicesViewByService;
            $scope.tableView.service.allItems = [...items];
            return items;
        }

        return null;
    }

    $scope.loadItems = function () {
        const type = $scope.viewType;

        $scope.globalFilter.val = '';
        $scope.pageManager.searchParams.eBillingProvider.value = $scope.selectedEBillingProviders.map(eBillingProvider => eBillingProvider.id).join("|");
        $scope.pageManager.searchParams.exportStatus.value = $scope.selectedExportStatuses.map(item => item.id).join("|");
        $scope.pageManager.searchParams.paymentStatus.value = $scope.selectedPaymentStatuses.map(item => item.id).join("|");
        $scope.pageManager.searchParams.billType.value = $scope.selectedBillType.length === 1 ? $scope.selectedBillType[0].id : "";
        $scope.pageManager.searchParams.manualHold.value = $scope.selectedManualHold.length === 1 ? $scope.selectedManualHold[0].id : "";
        $scope.pageManager.searchParams.hasCheckLine.value = $scope.selectedHasCheckLine.length === 1 ? $scope.selectedHasCheckLine[0].id : "";
        $scope.pageManager.searchParams.serviceType.value = $scope.selectedServices.map(item => item.id).join("|");
        
        $scope.pageManager.executeSearch().then(function (response) {
            const items = setAllItemsByType(response, type);

            if (!items) {
                throw new Error("Unknown view type");
            }

            initTable(items, type);
        }, function (error) {
            toaster.pop("error", "Failed to load invoices");
        });
    };

    $scope.clickTableRow = function (item) {
        if (!item) return;

        if ($scope.viewType === "Invoice") {
            $rootScope.openInvoiceModal({ invoiceId: item.id });
        }
        if ($scope.viewType === "Service") {
            $rootScope.openVisitInstanceModal(item.visitInstanceId);
        }
    };

    $scope.openModalToExportInvoices = function () {
        if ($scope.viewType === "Invoice") {
            billingInvoicesProcessor.openInvoicesExporterModalByInvoices(
                $scope.selectionLogic.getSelectedItems().map(item => item.id),
                $scope.loadItems
            );
        }
        if ($scope.viewType === "Service") {
            billingInvoicesProcessor.openInvoicesExporterModalByInvoiceVisits(
                $scope.selectionLogic.getSelectedItems().map(item => item.id),
                $scope.loadItems
            );
        }
    };

    $scope.openModalToForceExportInvoices = function () {
        if ($scope.viewType === "Invoice") {
            billingInvoicesProcessor.openInvoicesForceExporterModalByInvoices(
                $scope.selectionLogic.getSelectedItems().map(item => item.id),
                $scope.loadItems
            );
        }
        if ($scope.viewType === "Service") {
            billingInvoicesProcessor.openInvoicesForceExporterModalByInvoiceVisits(
                $scope.selectionLogic.getSelectedItems().map(item => item.id),
                $scope.loadItems
            );
        }
    };

    $scope.openModalToForceNewExportInvoices = function () {
        if ($scope.viewType === "Invoice") {
            billingInvoicesProcessor.openInvoicesForceNewExporterModalByInvoices(
                $scope.selectionLogic.getSelectedItems().map(item => item.id),
                $scope.loadItems
            );
        }
        if ($scope.viewType === "Service") {
            billingInvoicesProcessor.openInvoicesForceNewExporterModalByInvoiceServices(
                $scope.selectionLogic.getSelectedItems().map(item => item.id),
                $scope.loadItems
            );
        }
    };

    $scope.openModalToRecheckPendingInvoices = function () {
        const invoiceIds = $scope.viewType === "Invoice" ? 
            $scope.selectionLogic.getSelectedItems().map(item => item.id) :
            Array.from(new Set($scope.selectionLogic.getSelectedItems().map(item => item.invoiceId)));

        billingInvoicesProcessor.openRecheckPendingInvoicesModalByInvoices(invoiceIds, $scope.loadItems);
    };

    $scope.openModalToDeleteInvoices = function () {
        if ($scope.viewType === "Invoice") {
            const invoiceIds = [];
            $scope.selectionLogic.getSelectedItems().forEach(function (invoice) {
                invoiceIds.push(invoice.id);
            });

            SweetAlert.swal({
                title: "Delete invoices",
                text: "Are you sure you want to delete the invoices for the " + invoiceIds.length + " selected invoices? ",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3077EB",
                confirmButtonText: "Yes, remove",
                closeOnConfirm: true,
                closeOnCancel: true
            }, function (isConfirm) {
                if (isConfirm) {
                    DatabaseApi.post('agencies/' + $rootScope.agencyId +
                        '/agency_members/' + $rootScope.agencyMemberId + '/delete_invoices/', { invoiceIds }).then(function (res) {
                            toaster.pop('success', "Success", "Deleted invoices successfully");
                            $scope.loadItems();
                        }, function (err) {
                            toaster.pop('error', "Something went wrong", "Failed to delete invoices");
                        })
                }
            });
        }
    }

    $scope.openModalToUnexportInvoices = function () {
        const invoiceIds = [];
        $scope.selectionLogic.getSelectedItems().forEach(function (invoice) {
            invoiceIds.push(invoice.id);
        });

        if (invoiceIds.length > 0) {
            SweetAlert.swal({
                title: "Unexport invoices",
                text: "Are you sure you want to unexport these " + invoiceIds.length + " selected invoices? ",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3077EB",
                confirmButtonText: "Yes, unexport",
                closeOnConfirm: true,
                closeOnCancel: true
            }, function (isConfirm) {
                if (isConfirm) {
                    DatabaseApi.post(
                        `agencies/${$rootScope.agencyId}/agency_member/${$rootScope.agencyMemberId}/unexport_invoices`, 
                        { invoiceIds }).then(
                            () => {
                            toaster.pop('success', "Success", "Unexported invoices successfully");
                            $scope.loadItems();
                        }, () => {
                            toaster.pop('error', "Something went wrong", "Failed to unexport invoices");
                        })
                }
            });
        }
    };

    $scope.exportInvoiceAs = function (eBillingType) {
        const invoiceVisitIds = [];
        if ($scope.viewType === "Invoice") {
            $scope.selectionLogic.getSelectedItems().forEach((invoice) => {
                if (invoice.invoiceVisitIds !== null) {
                    invoiceVisitIds.push(...invoice.invoiceVisitIds);
                }
            });
        }

        if ($scope.viewType === "Service") {
            $scope.selectionLogic.getSelectedItems().forEach(row => {
                invoiceVisitIds.push(row.id);
            });
        }

        billingInvoicesProcessor.openInvoicesExporterModalByInvoiceServiceAs(invoiceVisitIds, $scope.loadItems, { eBillingType: eBillingType });
    }

    initialize();

    $scope.applyGlobalSearch = function (term) {
        const filter = { $: term };
        const tableParamsHolder = $scope.viewType === "Invoice" ? $scope.tableView.invoice : $scope.tableView.service;
        if (tableParamsHolder.tableParams) {
            angular.extend(tableParamsHolder.tableParams.filter(), filter);
            const allItems = $filter('filter')(tableParamsHolder.allItems, $scope.globalFilter.val);
            initSelection(allItems, $scope.viewType)
        }
    };

    $scope.getExportInvoiceTableRows = function(selected) {
        const items = selected ? $scope.selectionLogic.getSelectedItems() : $scope.selectionLogic.data.items;
        const rows = [];
        const titles = [
            'Exports',
            'Payment',
            'ID',
            'Date Range',
            'Total Billed Hours',
            'Total Billed',
            'Total Paid',
            'Total Units',
            'Patient',
            'Contract',
            "Office",
            "Service Codes",
            "Service Code IDs",
            'Invoice Batch ID',
            'Number of Visits',
            'Number of Administrative Payments',
            'Number of Services',
            'Created at',
            'Patient Medflyt ID',
            'Patient HHA Exchange ID',
            'Patient Admission ID',
            'Patient SSN',
            'Patient Medicare Number',
            'Patient Medicaid Number',
            'Patient Address',
            'Patient Gender'
        ];
        rows.push(titles);

        // Filter table data by global filter
        const allItems = $filter('filter')(items, $scope.globalFilter.val);

        allItems.forEach(dataRow => {
            const csvRow = [];
            titles.forEach(title => {
                let toPush = "";
                switch (title) {
                    case "Exports":
                        toPush = dataRow.exportedStatus || '';
                        break;
                    case "Payment":
                        toPush = dataRow.paymentStatus || '';
                        break;
                    case "ID":
                        toPush = dataRow.displayId.replace('#', '') || '';
                        break;
                    case "Date Range":
                        toPush = `${$filter("mfShortDate")(dataRow.startDate)} - ${$filter("mfShortDate")(dataRow.endDate)}`;
                        break;
                    case "Total Billed Hours":
                        toPush = $filter("secondsToLabel")(dataRow.totalBilledSeconds);
                        break;
                    case "Total Billed":
                        toPush = $filter("centsToDollars")(dataRow.totalBilled, true);
                        break;
                    case "Total Paid":
                        toPush = $filter("centsToDollars")(dataRow.totalPaid, true);
                        break;
                    case "Total Units":
                        toPush = dataRow.totalUnitsThousandths / 1000;
                        break;
                    case "Patient":
                        toPush = dataRow.patientName || '';
                        break;
                    case "Contract":
                        toPush = dataRow.contractTypeName || '';
                        break;
                    case "Office":
                        toPush = dataRow.officeName || '';
                        break;
                    case "Invoice Batch ID":
                        toPush = dataRow.invoiceBatchDisplayId.replace('#', '') || '';
                        break;
                    case "Number of Visits":
                        toPush = dataRow.visitCount || '';
                        break;
                    case "Number of Administrative Payments":
                        toPush = dataRow.administrativePaymentCount || '';
                        break;
                    case "Number of Services":
                        toPush = dataRow.serviceCount || '';
                        break;
                    case "Created at":
                        toPush = $filter("mfShortTime")(dataRow.createdAt, ['withDate']) || '';
                        break;
                    case "Patient Medflyt ID":
                        toPush = dataRow.patientId || '';
                        break;
                    case "Patient HHA Exchange ID":
                        toPush = dataRow.patientExternalId || '';
                        break;
                    case "Patient Admission ID":
                        toPush = dataRow.patientAdmissionId || '';
                        break;
                    case "Patient SSN":
                        toPush = dataRow.patientSSN || '';
                        break;
                    case "Patient Medicare Number":
                        toPush = dataRow.patientMedicareNumber || '';
                        break;
                    case "Patient Medicaid Number":
                        toPush = dataRow.patientMedicaidNumber || '';
                        break;
                    case "Patient Address":
                        toPush = dataRow.patientAddress || '';
                        break;
                    case "Patient Gender":
                        toPush = dataRow.patientGender || '';
                        break;
                    case "Service Codes":
                        toPush = dataRow.serviceCodes || '';
                        break;
                    case "Service Code IDs":
                        toPush = dataRow.serviceCodeIds || '';
                        break;
                    default:
                        break;
                }

                csvRow.push("\"" + toPush + "\"");
            });
            rows.push(csvRow);
        });

        return rows;
    }

    $scope.getExportServiceTableRows = function(selected) {
        const items = selected ? $scope.selectionLogic.getSelectedItems() : $scope.selectionLogic.data.items;
        const rows = [];
        const titles = [
            'Type',
            'Exports',
            'Payment',
            'Invoice ID',
            'Visit ID',
            'Service Date',
            'Total Billed Hours',
            'Total Billed',
            'Total Paid',
            'Total Units',
            'Patient',
            'Contract',
            "Office",
            "Service Code",
            "Service Code ID",
            'Invoice Batch ID',
            'Created at',
            'Patient Medflyt ID',
            'Patient HHA Exchange ID',
            'Patient Admission ID',
            'Patient SSN',
            'Patient Medicare Number',
            'Patient Medicaid Number',
            'Patient Address',
            'Patient Gender'
        ];
        rows.push(titles);

        // Filter table data by global filter
        const allItems = $filter('filter')(items, $scope.globalFilter.val);

        allItems.forEach(dataRow => {
            const csvRow = [];
            titles.forEach(title => {
                let toPush = "";
                switch (title) {
                    case "Type":
                        toPush = dataRow.serviceType || '';
                        break;
                    case "Exports":
                        toPush = dataRow.exportedStatus || '';
                        break;
                    case "Payment":
                        toPush = dataRow.paymentStatus || '';
                        break;
                    case "Invoice ID":
                        toPush = dataRow.displayId.replace('#', '') || '';
                        break;
                    case "Visit ID":
                        toPush = dataRow.visitInstanceId || '';
                        break;
                    case "Service Date":
                        toPush = $filter("mfShortDate")(dataRow.serviceDate);
                        break;
                    case "Total Billed Hours":
                        toPush = $filter("secondsToLabel")(dataRow.totalBilledSeconds);
                        break;
                    case "Total Billed":
                        toPush = $filter("centsToDollars")(dataRow.totalBilled, true);
                        break;
                    case "Total Paid":
                        toPush = $filter("centsToDollars")(dataRow.totalPaid, true);
                        break;
                    case "Total Units":
                        toPush = dataRow.totalUnitsThousandths / 1000;
                        break;
                    case "Patient":
                        toPush = dataRow.patientName || '';
                        break;
                    case "Contract":
                        toPush = dataRow.contractTypeName || '';
                        break;
                    case "Office":
                        toPush = dataRow.officeName || '';
                        break;
                    case "Invoice Batch ID":
                        toPush = dataRow.invoiceBatchDisplayId.replace('#', '') || '';
                        break;
                    case "Created at":
                        toPush = $filter("mfShortTime")(dataRow.createdAt, ['withDate']) || '';
                        break;
                    case "Patient Medflyt ID":
                        toPush = dataRow.patientId || '';
                        break;
                    case "Patient HHA Exchange ID":
                        toPush = dataRow.patientExternalId || '';
                        break;
                    case "Patient Admission ID":
                        toPush = dataRow.patientAdmissionId || '';
                        break;
                    case "Patient SSN":
                        toPush = dataRow.patientSSN || '';
                        break;
                    case "Patient Medicare Number":
                        toPush = dataRow.patientMedicareNumber || '';
                        break;
                    case "Patient Medicaid Number":
                        toPush = dataRow.patientMedicaidNumber || '';
                        break;
                    case "Patient Address":
                        toPush = dataRow.patientAddress || '';
                        break;
                    case "Patient Gender":
                        toPush = dataRow.patientGender || '';
                        break;
                    case "Service Code":
                        toPush = dataRow.serviceCode || '';
                        break;
                    case "Service Code ID":
                        toPush = dataRow.serviceCodeId || '';
                        break;
                    default:
                        break;
                }

                csvRow.push("\"" + toPush + "\"");
            });
            rows.push(csvRow);
        });

        return rows;
    }

    $scope.exportTable = function (selected) {
        $scope.loadingCSV = true;

        const rows = $scope.viewType === "Invoice" ? $scope.getExportInvoiceTableRows(selected) : $scope.getExportServiceTableRows(selected);

        let csvContent = "";
        rows.forEach(function (rowArray) {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM+csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", getExportedFileName());
        document.body.appendChild(link);

        link.click();
        $scope.loadingCSV = false;
    };

    $scope.handleEntitySelection = (type, id) => {
        $scope.selectedEntity.type = type;
        $scope.selectedEntity.id = id;
        $scope.pageManager.updateSearchParamValue("selectedPatientId", id);
    }

    $scope.handleEntityDeselect = () => {
        $scope.selectedEntity.id = null;
        $scope.selectedEntity.type = null;
        $scope.pageManager.updateSearchParamValue("selectedPatientId", null);
    }

    const getExportedFileName = function () {
        const filename = "medflyt-invoices"
            + $filter("date")(new Date($scope.pageManager.searchParams.from.value), "yyyy-MM-dd")
            + "-to-"
            + $filter("date")(new Date($scope.pageManager.searchParams.to.value), "yyyy-MM-dd")
            + ".csv";
        return filename;
    }

    $scope.manualHoldExtraSetting = {
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: false,
        enableSearch: true,
    };

    $scope.hasCheckLineExtraSetting = {
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: false,
        enableSearch: false,
    };

    $scope.billTypeExtraSetting = {
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: false,
        enableSearch: true,
    };
};
