import { LockIcon, SearchIcon } from "@chakra-ui/icons";
import { Button, InputGroup, InputRightElement } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import DataTable from "../../../../shared/components/DataTable/DataTable";
import useGraphQLDataTable from "../../../../shared/components/DataTable/useGraphQLDataTable";
import DebouncedInput from "../../../../shared/components/DebouncedInput";
import Page from "../../../../shared/components/Page";
import AddIcon from "../../../../shared/icons/AddIcon";
import { GetReferralUsersQuery } from "../../../../shared/schema/gql/graphql";
import { ReferralUserId } from "../../../../shared/schema/schema";
import { formatPhoneNumber } from "../../../../shared/utils/call-center";
import CreateReferralUserModalTrigger from "../../components/CreateReferralUserModalTrigger";
import ReferralUserLoginInfoModal from "../../components/ReferralUserLoginInfoModal";
import { GET_REFERRAL_USERS_QUERY } from "../../referral.graphql";

export default function ReferralsDashboardRoute() {
  const [loginInfo, setLoginInfo] = React.useState<{
    id: ReferralUserId;
    email: string;
    password: string | undefined;
  } | null>(null);

  const columns = React.useMemo(
    () =>
      createReferralsTableColumn({
        onClickShowLoginInfo: (id, email) => setLoginInfo({ id, email, password: undefined }),
      }),
    []
  );

  const { table, query, globalFilters, setFilter, metaButtonProps } = useGraphQLDataTable({
    document: GET_REFERRAL_USERS_QUERY,
    connection: "referralUsers",
    columns: columns,
    columnVisiblity: {
      storage: { key: "referrals/dashboard", version: 1 },
      forceVisibleColumns: React.useMemo(() => ["id"], []),
      initialSelected: ["name", "phoneNumber", "email", "role", "organization", "createdAt"],
    },
    initialSorting: [{ id: "createdAt", desc: true }],
    globalFilters: {
      initialState: {
        search: "",
      },
    },
  });

  const filterNode = (
    <>
      <InputGroup width="md">
        <DebouncedInput
          debounce={200}
          placeholder="Search by name, phone number, etc"
          value={(globalFilters.search as string | undefined) ?? ""}
          onChange={(x) => setFilter("search", x)}
        />
        <InputRightElement>
          <SearchIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>
    </>
  );

  const actionNode = (
    <CreateReferralUserModalTrigger
      onCreated={(id, email, password) => {
        query.refetch();
        setLoginInfo({ id, email, password });
      }}
    >
      <Button aria-label="Add referrer" as={Button} colorScheme="blue" leftIcon={<AddIcon />}>
        Add referrer
      </Button>
    </CreateReferralUserModalTrigger>
  );

  return (
    <>
      <Page isLoading={query.isPending || query.isFetching}>
        <Page.Header>
          <Page.Title>Referral Users ({query.data?.referralUsers.totalCount ?? "..."})</Page.Title>
        </Page.Header>

        <Page.Content p={0}>
          <DataTable
            actionNode={actionNode}
            filterNode={filterNode}
            isLoading={query.isPending}
            metaButtonProps={metaButtonProps}
            spacing="tight"
            table={table}
          />
        </Page.Content>
      </Page>
      {loginInfo ? (
        <ReferralUserLoginInfoModal
          key="login-info-modal"
          email={loginInfo.email}
          id={loginInfo.id}
          isOpen={true}
          password={loginInfo.password}
          onClose={() => setLoginInfo(null)}
          onResetPassword={(password) => setLoginInfo({ ...loginInfo, password })}
        />
      ) : (
        <ReferralUserLoginInfoModal
          key="login-info-modal"
          email=""
          id={ReferralUserId.parse(0)}
          isOpen={false}
          onClose={() => {}}
          onResetPassword={() => {}}
        />
      )}
    </>
  );
}

type ReferralUser = GetReferralUsersQuery["referralUsers"]["nodes"][number];

function createReferralsTableColumn(params: {
  onClickShowLoginInfo: (id: ReferralUserId, email: string) => void;
}) {
  const { accessor, display } = createColumnHelper<ReferralUser>();

  const columns = [
    accessor("name", {
      header: "Name",
      meta: { gqlFilterKey: "nameSearch", gqlSortKey: "name" },
    }),
    accessor("id", {
      header: "ID",
    }),
    display({
      id: "_login",
      cell: (props) => (
        <Button
          _hover={{ bg: "blue.100" }}
          colorScheme="blue"
          leftIcon={<LockIcon h={5} w={5} />}
          variant="ghost"
          onClick={(e) => {
            e.stopPropagation();
            params.onClickShowLoginInfo(props.row.original.id, props.row.original.email);
          }}
        >
          Login
        </Button>
      ),
    }),
    accessor("phoneNumber", {
      header: "Phone Number",
      meta: { gqlFilterKey: "phoneSearch", gqlSortKey: "phoneNumber" },
      cell: (props) => formatPhoneNumber(props.row.original.phoneNumber),
    }),
    accessor("email", {
      header: "Email",
      meta: { gqlFilterKey: "emailSearch", gqlSortKey: "email" },
    }),
    accessor("role", {
      header: "Role",
      meta: { gqlFilterKey: "role", gqlSortKey: "role" },
    }),
    accessor("organization", {
      header: "Organization",
      meta: { gqlFilterKey: "organization", gqlSortKey: "organization" },
    }),
    accessor("createdAt", {
      header: "Created At",
      meta: { gqlSortKey: "createdAt" },
    }),
  ];

  return columns;
}
