//! @ngInject
export function billingInvoiceDetailsCtrl($scope, $rootScope, $stateParams, $uibModal, DatabaseApi, NgTableParams, generalUtils, toaster, billingVisitActionDataGenerator, selectionLogic, paginationLogic, billingInvoicesProcessor, SweetAlert, $state) {

    $scope.invoice = null;    
    $scope.openPatientModal = $rootScope.openPatientModal;
    const initializeMap = DatabaseApi.entitiesInitializeMap();
    $scope.assetsAreReady = false;    
    $scope.sections = {
        activeIdx: 0,
        list: [
          { label: "Summary", id: "scroll-summary" },
          { label: "Visits", id: "scroll-visits" },
          { label: "Deleted visits", id: "scroll-deleted-visits" },
          { label: "History", id: "scroll-history" },
        ]
    };    

    $scope.handleClickSectionMenu = (section) => {
        $scope.sections.activeIdx = $scope.sections.list.indexOf(section);

        const activeSection = $scope.sections.list[$scope.sections.activeIdx];

        generalUtils.scrollToElement(activeSection.id);
    }

    function initialize() {
        $scope.caregiversMap = DatabaseApi.caregivers() || {};
        $scope.officesMap = DatabaseApi.offices() || {};
        $scope.serviceCodesMap = DatabaseApi.serviceCodes() || {};
        $scope.invoice = $stateParams.invoice;
        $scope.visitsSelectionLogic = selectionLogic.createNewVirtualizedLogic();
        checkEssentialAssets();
        if(!$scope.assetsAreReady) return;
        loadInvoice();
    }

    function checkEssentialAssets() {
        const officesState = initializeMap['offices'];       
        const caregiversState = initializeMap['caregivers'];
        const serviceCodesState = initializeMap['serviceCodes'];
    
        if (officesState && caregiversState && serviceCodesState) {
            $scope.assetsAreReady = true;
            return;
        }
        $scope.assetsAreReady = false;
        return;
      }

    $scope.$on("refresh_visits", function () {
        loadInvoice();
    })

    function loadInvoice() {
        var invoiceId = ($scope.invoice && $scope.invoice.id) || $scope.$resolve.invoice.id || $stateParams.id || generalUtils.getLastPartInUrlPath();
        if (invoiceId) {
            clearTableParams();

            var url = "agencies/" + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + "/invoices/" + invoiceId;
            DatabaseApi.get(url).then(function (res) {
                $scope.invoice = res.data;

                var agencyMembers = DatabaseApi.getAgencyMembersArr();
                var memberCreated = agencyMembers.find(function (member) {
                    return member.id === $scope.invoice.createdBy;
                });
                var memberUpdated = agencyMembers.find(function (member) {
                    return member.id === $scope.invoice.updatedBy;
                });
                $scope.invoice.createdByName = memberCreated ? memberCreated.firstName + ' ' + memberCreated.lastName : $scope.invoice.createdBy;
                $scope.invoice.updatedByName = memberUpdated ? memberUpdated.firstName + ' ' + memberUpdated.lastName : $scope.invoice.updatedBy;
                
                let officeName = "No office";

                if ($scope.invoice.officeId){
                    const office = $scope.officesMap.find(({ id }) => $scope.invoice.officeId === id);
                    officeName = office.name;            
                }

                $scope.invoice.currentInvoiceVisitDetails.forEach((visit) => {
                    visit.officeName = officeName;
                });

                initTables();
                unionInvoiceTotalClaimNumbers();
                $scope.invoice.allCheckNumbers = getAllCheckNumbers();
            }, function (error) {
                toaster.pop("error", "Failed to load the invoice details");
            });
        }
    }

    $scope.goToCheckPage = (id) => {
        $state.go('app.billing.check', { id: id, check: null });
    };

    function initLogics(itemIds) {
        initSelection(itemIds || []);

        var countPerPage = $scope.visitsPaginationLogic && $scope.visitsPaginationLogic.data && $scope.visitsPaginationLogic.data.countPerPage;
        initPagination($scope.visitsSelectionLogic.countAll(), countPerPage);
    };

    var initSelection = function (itemIds) {
        if (itemIds) {
            $scope.visitsSelectionLogic.initItemIdsCollection(itemIds);
        }
    };

    var initPagination = function (totalAmountOfItems, countPerPage) {
        var onPageSelected = function (eventObject, pageNumber, firstItemIndex, amountOfItems) {
            $scope.loadPageItems(pageNumber, firstItemIndex, amountOfItems);
        };

        $scope.visitsPaginationLogic = paginationLogic.createNewLogic(onPageSelected);
        $scope.visitsPaginationLogic.initPaginationData(totalAmountOfItems, countPerPage || 25, [10, 25, 50, 100]);
    };

    $scope.loadPageItems = function (pageNumber, firstItemIndex, amountOfItems) {
        var total = $scope.invoice.currentInvoiceVisitDetails.length;
        var startIndex = (pageNumber - 1) * amountOfItems;
        var pageAmount = startIndex + amountOfItems >= total ? total : amountOfItems;
        var pageItems = $scope.invoice.currentInvoiceVisitDetails.slice(startIndex, startIndex + pageAmount);
        // console.log("total", total, "startIndex", startIndex, "pageAmount", pageAmount, "pageItems", pageItems);
        $scope.visitsSelectionLogic.updateItemsToRender(pageItems);
    };

    $scope.exportVisits = function (force, asNew) {
        const selectedInvoiceVisitIds = $scope.visitsSelectionLogic.getAllSelectedItemIds()

        if (force && asNew) {
            billingInvoicesProcessor.openInvoicesForceNewExporterModalByInvoiceServices(selectedInvoiceVisitIds, loadInvoice);
        } 
        else if (force && !asNew) {
            billingInvoicesProcessor.openInvoicesForceExporterModalByInvoiceVisits(selectedInvoiceVisitIds, loadInvoice);   
        }else {
            billingInvoicesProcessor.openInvoicesExporterModalByInvoiceVisits(selectedInvoiceVisitIds, loadInvoice);
        }
    };

    $scope.exportInvoiceAs = function (eBillingType) {
        billingInvoicesProcessor.openInvoicesExporterModalByInvoiceServiceAs($scope.visitsSelectionLogic.getAllSelectedItemIds(), loadInvoice, { eBillingType: eBillingType });
    }

    $scope.unexportVisits = function () {
        var url = "agencies/" + $rootScope.agencyId + "/agency_member/" + $rootScope.agencyMemberId + "/unexport_visits";
        var body = {
            exportVisitIds: $scope.visitsSelectionLogic.getAllSelectedItemIds().map(function (itemId) {
                var currentInvoiceVisitDetails = $scope.invoice.currentInvoiceVisitDetails.find(function (v) {
                    return v.id === itemId;
                });
                return currentInvoiceVisitDetails.exportVisits[currentInvoiceVisitDetails.exportVisits.length - 1].id;
            })
        };
        if (body.exportVisitIds.length > 0) {
            DatabaseApi.post(url, body).then(function (res) {
                loadInvoice();
                toaster.pop('success', 'Visits unexported successfully');
            }, function (err) {
                toaster.pop('error', 'Oops! Something went wrong');
            });
        }
    };

    var clearTableParams = function () {
        $scope.visitsTableParams = null;
        $scope.deletedVisitsTableParams = null;
        $scope.activityTableParams = null;
    };

    function initTables() {
        attachViewExportedFnToVisitsDetails();
        initVisitsTable($scope.invoice.currentInvoiceVisitDetails);
        initDeletedVisitsTable($scope.invoice.deletedInvoiceVisitDetails);
        initActivityTable($scope.invoice.historyInvoiceVisitDetails);
    };

    function attachViewExportedFnToVisitsDetails() {
        $scope.invoice.currentInvoiceVisitDetails.forEach(visitInstance => {
            visitInstance.viewExportedInvoicesFn = viewExportedInvoices;
        });
    }

    var mapItems = function (items) {
        items.forEach(function (item) {
            if (!item.contractTypeName && item.contractId) {
                var contract = $scope.contractTypesData.mapped[item.contractId];
                item.contractTypeName = (contract && contract.name) || item.contractId;
            }

            if (item.caregiverId) {
                item.caregiver = $scope.caregiversMap[item.caregiverId];
            }

            if (item.serviceCodeId){
                const serviceCode = $scope.serviceCodesMap.find(({ id }) => item.serviceCodeId === id);
                item.serviceCodeName = serviceCode ? serviceCode.code : "No service code";            
            }

            item.patientName = $scope.invoice.patientName;
        });
    };

    var initVisitsTable = function (items) {
        var ids = items.map(function (v) {
            return v.id;
        });

        initLogics(ids);
        if ($scope.visitsSelectionLogic.countAll() > 0) {
            $scope.visitsPaginationLogic.selectFirstPage();
        }

        mapItems(items);
        $scope.visitsSelectionLogic.updateItemsToRender(items);

        var options = {
            count: $scope.visitsPaginationLogic.data.countPerPage
        };
        $scope.visitsTableParams = new NgTableParams(options, {
            counts: [],
            dataset: items
        });
    };

    var initDeletedVisitsTable = function (items) {
        mapItems(items);

        var options = {
            count: 25
        };
        $scope.deletedVisitsTableParams = new NgTableParams(options, {
            dataset: items
        });
    };

    var initActivityTable = function (items) {
        mapItems(items);

        var options = {
            count: 25
        };
        $scope.activityTableParams = new NgTableParams(options, {
            //showing no results for the activity table till they will be as expected
            dataset: items
        });
    };

    function getAllCheckNumbers() {
        const checkNumberArrays = $scope.invoice.currentInvoiceVisitDetails.map(({checkNumbers}) => checkNumbers ?? []);
        return Array.from(new Set([].concat(...checkNumberArrays)));
    }

    function unionInvoiceTotalClaimNumbers() {
        let allVisitsClaimNumbers = [];
        $scope.invoice.currentInvoiceVisitDetails.forEach(visit => {
            if (visit.claimNumbers && visit.claimNumbers.length > 0) {
                allVisitsClaimNumbers = new Set([...allVisitsClaimNumbers, ...visit.claimNumbers]);
            }
        });

        $scope.invoice.totalClaimNumbers = Array.from(allVisitsClaimNumbers);
    }

    $scope.clickTableRow = function (row) {
        if (!row) return;
        $rootScope.openVisitInstanceModal(row.visitInstanceId);
    };

    var deleteCurrentVisit = function (event, visit) {
        SweetAlert.swal({
            title: 'Remove visit from invoice',
            text: 'Are you sure you want to remove this visit from invoice?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3077EB',
            confirmButtonText: 'Remove',
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                var invoiceVisitId = $scope.invoice.currentInvoiceVisitDetails.find(function (invoiceVisitDetails) {
                    return invoiceVisitDetails.visitInstanceId === visit.visitInstanceId;
                }).id
                var url = 'agencies/' + $rootScope.agencyId +
                    '/agency_members/' + $rootScope.agencyMemberId +
                    '/invoice_visits/' + invoiceVisitId;
                DatabaseApi.delete(url).then(function (res) {
                    onCurrentVisitDeleted(visit);
                }, function (err) {
                    toaster.pop('error', 'Something went wrong');
                });
            }
        });

    };

    const viewExportedInvoices = function ($event, visit) {
        $event.stopPropagation();

        $uibModal
            .open({
                templateUrl: "admin/views/billing-exported-invoices-modal.html",
                size: "md",
                controller: "billingExportedInvoicesModalCtrl",
                windowClass: "billing-exported-invoices-modal-ctrl center-center",
                resolve: {
                    visit
                }
            });
    }

    var removeVisitFromCurrentVisits = function (visit) {
        var indexOfVisit = $scope.invoice.currentInvoiceVisitDetails.indexOf(visit);
        var leftVisits = $scope.invoice.currentInvoiceVisitDetails.slice(0, indexOfVisit);
        var rightVisits = $scope.invoice.currentInvoiceVisitDetails.slice(indexOfVisit + 1, $scope.invoice.currentInvoiceVisitDetails.length);

        $scope.invoice.currentInvoiceVisitDetails = leftVisits.concat(rightVisits);

        var options = {
            count: $scope.visitsTableParams.count()
        };

        $scope.visitsTableParams = new NgTableParams(options, {
            dataset: $scope.invoice.currentInvoiceVisitDetails
        });
    };

    var addVisitToDeletedVisits = function (visit) {
        $scope.invoice.deletedInvoiceVisitDetails.push(visit);
        var options = {
            count: $scope.deletedVisitsTableParams.count()
        };
        $scope.deletedVisitsTableParams = new NgTableParams(options, {
            dataset: $scope.invoice.deletedInvoiceVisitDetails
        });
    };

    var onCurrentVisitDeleted = function (visit) {
        removeVisitFromCurrentVisits(visit);
        addVisitToDeletedVisits(visit);
    };

    $scope.visitActionsData = [
        ...($rootScope.isPermittedByKey("delete_visit_from_invoice") ?
        [billingVisitActionDataGenerator.createNewVisitActionData({
            displayText: "",
            className: "btn-danger glyphicon glyphicon-trash",
            tooltip: "Remove from invoice",
        }, deleteCurrentVisit)] : []),
    ];

    $scope.updateInvoice = function () {
        SweetAlert.swal({
            title: 'Update invoice',
            text: 'Are you sure you want to update invoice ' + $scope.invoice.displayId + ' ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3077EB',
            confirmButtonText: 'Update',
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                var url = 'agencies/' + $rootScope.agencyId +
                    '/agency_members/' + $rootScope.agencyMemberId +
                    '/update_outdated_invoice/' + $scope.invoice.id;
                DatabaseApi.post(url, {}).then(function (res) {
                    loadInvoice();
                    toaster.pop('success', 'Invoice updated successfully');
                }, function (err) {
                    toaster.pop('error', 'Oops! Something went wrong');
                });
            }
        });
    }

    $scope.recheckInvoicePending = function () {
        SweetAlert.swal({
            title: 'Recheck invoice pending',
            text: 'Are you sure you want to recheck invoice ' + $scope.invoice.displayId + ' ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3077EB',
            confirmButtonText: 'Check',
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                var url = 'agencies/' + $rootScope.agencyId +
                    '/agency_members/' + $rootScope.agencyMemberId +
                    '/recheck_pending_invoices';
                DatabaseApi.post(url, {
                    invoiceIds: [$scope.invoice.id]
                }).then(function (res) {
                    loadInvoice();
                    toaster.pop('success', 'Invoice rechecked successfully');
                }, function (err) {
                    toaster.pop('error', 'Oops! Something went wrong');
                });
            }
        });
    }

    $scope.navigationItems = {
        active: 'scroll-summary',
        list: [
            {
                id: 'scroll-summary',
                title: 'Summary'
            },
            {
                id: 'scroll-visits',
                title: 'Visits'
            },
            {
                id: 'scroll-deleted-visits',
                title: 'Deleted Visits'
            },
            {
                id: 'scroll-history',
                title: 'History'
            }
        ]
    };

    $scope.goToItem = function (item) {
        if (!item) {
            return;
        }
        $scope.navigationItems.active = item.id;
        generalUtils.scrollToElement(item.id);
    };

    $scope.setManualHold = function (hold) {
        if (hold === true || hold === false) {
            SweetAlert.swal({
                title: 'Invoice hold status',
                text: 'Are you sure you want to change invoice ' + $scope.invoice.displayId + ' hold status?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3077EB',
                confirmButtonText: hold ? "Set hold" : "Release hold",
                closeOnConfirm: true,
                closeOnCancel: true
            }, function (isConfirm) {
                if (isConfirm) {
                    var url = 'agencies/' + $rootScope.agencyId +
                        '/agency_members/' + $rootScope.agencyMemberId +
                        '/invoices/' + $scope.invoice.id;
                    DatabaseApi.put(url, {manualHold: hold}).then(function (res) {
                        loadInvoice();
                        toaster.pop('success', 'Invoice updated successfully');
                    }, function (err) {
                        toaster.pop('error', 'Oops! Something went wrong');
                    });
                }
            });
        }
    }

    initialize();

    $rootScope.$on("got_offices", () => {
        $scope.officesMap = DatabaseApi.offices();
        checkEssentialAssets();
    
        if ($scope.assetsAreReady) {
            initialize();
        }
      });
    
      $rootScope.$on("got_caregivers_data", () => {
          $scope.caregiversMap = DatabaseApi.caregivers();
          checkEssentialAssets();
    
          if ($scope.assetsAreReady) {
              initialize();
          }
      });

      $rootScope.$on("got_service_codes", () => {
        $scope.serviceCodesMap = DatabaseApi.serviceCodes();
        checkEssentialAssets();
  
        if ($scope.assetsAreReady) {
            initialize();
        }
    });
};